.ventas-transition-enter {
  max-height: 0;
  overflow: hidden;
}
.ventas-transition-enter-active {
  max-height: 500px; /* Ajusta este valor según el contenido */
  transition: max-height 600ms ease-in-out;
}
.ventas-transition-exit {
  max-height: 500px; /* Debe coincidir con el valor de enter-active */
  overflow: hidden;
}
.ventas-transition-exit-active {
  max-height: 0;
  transition: max-height 600ms ease-in-out;
}

.stock-transition-enter {
  max-height: 0;
  overflow: hidden;
}
.stock-transition-enter-active {
  max-height: 500px; /* Ajusta este valor según el contenido */
  transition: max-height 600ms ease-in-out;
}
.stock-transition-exit {
  max-height: 500px; /* Debe coincidir con el valor de enter-active */
  overflow: hidden;
}
.stock-transition-exit-active {
  max-height: 0;
  transition: max-height 600ms ease-in-out;
}
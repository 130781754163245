.fade-appear,
.fade-enter {
  transform: scale(0.9);
}

.fade-appear-active,
.fade-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}